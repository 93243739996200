










































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { SessionClosingInfoProperties } from "./SessionClosingInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SessionClosingDetailDialogProperties } from "./SessionClosingDetailDialogView.vue";
import { SessionClosingDetailImportProperties } from "./SessionClosingDetailImportView.vue";
import SessionClosingDetailService from "@/services/SessionClosingDetailService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CommonUtil from "@/utilities/CommonUtil";
import StringUtil from '@/utilities/StringUtil';

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 20,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false
        },
        filter: {
            customer: "",
            comment: "",
            productName: ""
        },
        saving: false,
        details: []
    })
})
export default class SessionClosingDetailView extends Vue {
    @Prop() private properties: SessionClosingInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private sessionClosingDetailDialog: SessionClosingDetailDialogProperties;
    @Prop() private sessionClosingDetailImport: SessionClosingDetailImportProperties;
    private sessionClosingDetailService = new SessionClosingDetailService();
    private queueHandle: number = 0;

    public get headers() {
        return [
            {
                text: this.$t("text.line-no"),
                value: "lineNo",
                width: "10px"
            },
            {
                text: this.$t("text.customer"),
                value: "customer",
                width: "16%"
            },
            {
                text: this.$t("text.comment"),
                value: "comment",
                width: "28%"
            },
            {
                text: this.$t("text.product-name"),
                value: "productName"
            },
            {
                text: this.$t("text.quantity"),
                value: "quantity",
                align: "right",
                width: "90px"
            },
            {
                text: this.$t("text.price"),
                value: "price",
                align: "right",
                width: "90px"
            },
            {
                text: this.$t("text.total-weight-g"),
                value: "totalWeight",
                align: "right",
                width: "130px"
            },
            {
                text: this.$t("text.total-amount"),
                value: "totalAmount",
                align: "right",
                width: "130px"
            },
            {
                text: "",
                value: "action",
                align: "center",
                width: "110px"
            }
        ];
    }

	public isFinalized() {
		const sessionClosing = this.properties.sessionClosing;
		return sessionClosing.status === "Finalized";
	}

	public navigatorUrl(item: any) {
		const liveSessionDetail = item.liveSessionDetail;
		const facebookAccountId = liveSessionDetail.facebookAccountId;
		return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
	}

    public created() {
        this.properties.events.subscribe("loaded", this.load);
        this.properties.events.subscribe("load-detail", this.load);
        this.properties.events.subscribe("alert-dialog-clicked", this.alertDialogClicked);
        this.load();
    }

    public destroyed() {
        this.properties.events.remove("loaded", this.load);
        this.properties.events.remove("load-detail", this.load);
        this.properties.events.remove("alert-dialog-clicked", this.alertDialogClicked);
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public async load() {
        const sessionClosing = this.properties.sessionClosing;
        if (sessionClosing && sessionClosing.id) {
            const pageNo = this.$data.table.pageNo;
            const rowCount = this.$data.table.recordCount;
            const rowStart = (pageNo - 1) * rowCount;

            this.$data.table.loading = true;
            const filter = this.$data.filter;
            try {
                const r = await this.sessionClosingDetailService.get({
                    rowStart: rowStart,
                    rowCount: rowCount,
                    countRecord: true,
                    sessionClosingId: sessionClosing.id,
                    productName: StringUtil.enclose(filter.productName, '%', '%'),
                    searchCustomer: filter.customer,
					searchComment: filter.comment,
					loadLiveSessionDetail: true,
                    loadFacebookComment: true,
					loadFacebookAccount: true
                });
                var details: any[] = r.data.sessionClosingDetails;
                details = details.sort((lhs, rhs) => rhs.id - lhs.id);

				const labelPsid = this.$t('text.psid');
				const labelRemarks = this.$t('text.remarks');
				for (var i = 0; i < details.length; i++) {
					const d = details[i];
					const s = d.liveSessionDetail;
					const c = s.facebookComment;
					const f = s.facebookAccount;
					const n = f.fullName;
					const u = f.uniqueNo ?? null;
					const p = f.pageScopedId;
					const g = c?.message ?? "";
					const r = d.remarks ?? "";

					const t0 = u !== null ? `${n} [${u}]` : n;
					const t1 = p !== "" ? `${labelPsid}: ${p}` : "";
					const t2 = r !== "" ? `${labelRemarks}: ${r}` : "";

					d.lineNo = rowStart + 1 + i;
					d.customer = `${t0}\n${t1}`.trim();
					d.comment = `${g}\n${t2}`.trim();
				}
                this.$data.details = details;

                var count = Math.ceil(r.data.count / rowCount);
                if (count < 1) count = 1;
                await Vue.nextTick();
                this.$data.table.pageCount = count;
                if (pageNo > count) {
                    this.$data.table.pageNo = count;
                }
            } catch (e) {
                if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                    var m = ExceptionUtil.getMessage(e);
                    AlertDialogProperties.error(this.alertDialog, null, m);
                    this.alertDialog.visible = true;
                }
            } finally {
                this.$data.table.loading = false;
            }
        }
    }

    public modify(record: any) {
        this.sessionClosingDetailDialog.detail = CommonUtil.clone(record);
        this.sessionClosingDetailDialog.visible = true;
    }

    public async remove(record: any) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.sessionClosingDetailService.delete(record);
            await this.load();
            await this.properties.events.fire('load-customer');
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public importDetail() {
        this.sessionClosingDetailImport.visible = true;
    }

    public removeDialog(record: any) {
        var m = "No: " + record.lineNo;

        AlertDialogProperties.delete(this.alertDialog, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "remove-detail", record };
        this.alertDialog.visible = true;
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "remove-detail" && button === this.$t("text.yes")) {
                this.remove(tag.record);
            }
        }
    }
}
